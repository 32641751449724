<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
    <v-list nav>
        <v-list-item v-for="menu in menus" :key="menu.title" :to="menu.url">
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!--  -->
      <template v-slot:append>
        <div class="pa-3">
          <v-btn block @click="logout">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>ChillStack 勤怠管理</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <!--  -->
      <router-view v-bind="$props" />

    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
// import Base from './Base.vue'
import firebase   from 'firebase/app';

export default {
  name: 'App',
  components: {

  },
    data: () => ({ 
      drawer: null,
      menus: [
      { title: '勤怠入力', icon: 'mdi-web', url: '/attendance' },
      { title: '勤怠履歴', icon: 'mdi-home', url: '/history' },
    ]
    }),
    props: {
      defaultDate:{
        type: String,
        default: new Date().toISOString().substr(0, 10)
      }
    },
    methods: {
        logout: function() {
            firebase.auth().signOut().then(
              this.$router.push({ path: '/Login' },() => {},() => {})
            );
        }
    }
};
</script>
