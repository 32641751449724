const firebaseConfig = {
  apiKey: "AIzaSyBiua3wwTPwELESn0hhpPwG5Jqe5QsrPKY",
  authDomain: "attendance-1f212.firebaseapp.com",
  projectId: "attendance-1f212",
  storageBucket: "attendance-1f212.appspot.com",
  messagingSenderId: "279686319545",
  appId: "1:279686319545:web:696c9da48aef8b1f5ffa68",
  measurementId: "G-3ZR4Y1QQKW"
};

module.exports = firebaseConfig
