<template>
    <v-container>
        <div>
            <!-- <v-btn @click="logout">LOGOUT</v-btn> -->
        </div>
        <div id="firebaseui-auth-container"></div>
    </v-container>
</template>
<script>
import firebase   from 'firebase/app';
import firebaseui from 'firebaseui-ja'
import 'firebaseui-ja/dist/firebaseui.css'


export default {
    mounted() {
        var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        ui.start('#firebaseui-auth-container',{
            signInSuccessUrl: '/',
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            ],
        });
    },
    methods: {
        logout: function() {
            firebase.auth().signOut().then(
                () => console.log("Sign out.")
            );
        }
    }
}
</Script>