/**
 * Attendance API
 * 勤怠管理API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Attendance from '../model/Attendance';
import PaginatedAttendanceList from '../model/PaginatedAttendanceList';
import PatchedAttendance from '../model/PatchedAttendance';
import TimeSummary from '../model/TimeSummary';

/**
* Attendances service.
* @module api/AttendancesApi
* @version 1.0.0
*/
export default class AttendancesApi {

    /**
    * Constructs a new AttendancesApi. 
    * @alias module:api/AttendancesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {module:model/Attendance} attendance 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Attendance} and HTTP response
     */
    attendancesCreateWithHttpInfo(attendance) {
      let postBody = attendance;
      // verify the required parameter 'attendance' is set
      if (attendance === undefined || attendance === null) {
        throw new Error("Missing the required parameter 'attendance' when calling attendancesCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['FirebaseAuth', 'basicAuth', 'cookieAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Attendance;
      return this.apiClient.callApi(
        '/attendances/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/Attendance} attendance 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Attendance}
     */
    attendancesCreate(attendance) {
      return this.attendancesCreateWithHttpInfo(attendance)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Attendance} and HTTP response
     */
    attendancesCurrentRetrieveWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['FirebaseAuth', 'basicAuth', 'cookieAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Attendance;
      return this.apiClient.callApi(
        '/attendances/current/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Attendance}
     */
    attendancesCurrentRetrieve() {
      return this.attendancesCurrentRetrieveWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} id A UUID string identifying this attendance.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    attendancesDestroyWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling attendancesDestroy");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['FirebaseAuth', 'basicAuth', 'cookieAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/attendances/{id}/', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} id A UUID string identifying this attendance.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    attendancesDestroy(id) {
      return this.attendancesDestroyWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Date} opts.createdAfter 
     * @param {Date} opts.createdBefore 
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {String} opts.user 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaginatedAttendanceList} and HTTP response
     */
    attendancesListWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'created_after': opts['createdAfter'],
        'created_before': opts['createdBefore'],
        'page': opts['page'],
        'user': opts['user']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['FirebaseAuth', 'basicAuth', 'cookieAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PaginatedAttendanceList;
      return this.apiClient.callApi(
        '/attendances/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Date} opts.createdAfter 
     * @param {Date} opts.createdBefore 
     * @param {Number} opts.page A page number within the paginated result set.
     * @param {String} opts.user 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaginatedAttendanceList}
     */
    attendancesList(opts) {
      return this.attendancesListWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} id A UUID string identifying this attendance.
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchedAttendance} opts.patchedAttendance 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Attendance} and HTTP response
     */
    attendancesPartialUpdateWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['patchedAttendance'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling attendancesPartialUpdate");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['FirebaseAuth', 'basicAuth', 'cookieAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Attendance;
      return this.apiClient.callApi(
        '/attendances/{id}/', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} id A UUID string identifying this attendance.
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchedAttendance} opts.patchedAttendance 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Attendance}
     */
    attendancesPartialUpdate(id, opts) {
      return this.attendancesPartialUpdateWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Date} opts.gt 
     * @param {Date} opts.lt 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TimeSummary} and HTTP response
     */
    attendancesRemainWorktimeRetrieveWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'gt': opts['gt'],
        'lt': opts['lt']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['FirebaseAuth', 'basicAuth', 'cookieAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TimeSummary;
      return this.apiClient.callApi(
        '/attendances/remain_worktime/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Date} opts.gt 
     * @param {Date} opts.lt 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TimeSummary}
     */
    attendancesRemainWorktimeRetrieve(opts) {
      return this.attendancesRemainWorktimeRetrieveWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} id A UUID string identifying this attendance.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Attendance} and HTTP response
     */
    attendancesRetrieveWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling attendancesRetrieve");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['FirebaseAuth', 'basicAuth', 'cookieAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Attendance;
      return this.apiClient.callApi(
        '/attendances/{id}/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} id A UUID string identifying this attendance.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Attendance}
     */
    attendancesRetrieve(id) {
      return this.attendancesRetrieveWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} id A UUID string identifying this attendance.
     * @param {module:model/Attendance} attendance 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Attendance} and HTTP response
     */
    attendancesUpdateWithHttpInfo(id, attendance) {
      let postBody = attendance;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling attendancesUpdate");
      }
      // verify the required parameter 'attendance' is set
      if (attendance === undefined || attendance === null) {
        throw new Error("Missing the required parameter 'attendance' when calling attendancesUpdate");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['FirebaseAuth', 'basicAuth', 'cookieAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Attendance;
      return this.apiClient.callApi(
        '/attendances/{id}/', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} id A UUID string identifying this attendance.
     * @param {module:model/Attendance} attendance 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Attendance}
     */
    attendancesUpdate(id, attendance) {
      return this.attendancesUpdateWithHttpInfo(id, attendance)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
