export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_SIGNIN = 'AUTH_SIGNIN'
export const GET_ATTENDANCES = 'GET_ATTENDANCES'
export const GET_CURRENT_ACTION = 'GET_CURRENT_ACTION'
export const ADD_ATTENDANCES = 'ADD_ATTENDANCES'
export const GET_TODAY_SUMMARY = 'GET_TODAY_SUMMARY'
export const DELETE_TIMESTAMP = 'DELETE_TIMESTAMP'
export const EDIT_ATTENDANCE = 'EDIT_ATTENDANCE'
export const GET_MONTHLY_SUMMARY= 'GET_MONTHLY_SUMMARY'
export const TOGGLE_LOADING= 'TOGGLE_LOADING'


