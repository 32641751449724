<template>
  <div>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          :show-current="false"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="updateDate(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-container
      v-for="align in alignments"
      :key="align"
      class="grey lighten-5 mb-6"
    >
      <v-row :align="align" no-gutters justify="center">
          <v-col>
            <v-btn
              elevation="2"
              width="160"
              :disabled="!canSelectBeginWork"
              color="primary"
              @click="stampTime(1)"
            >
              出勤</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              elevation="2"
              width="160"
              :disabled="!canSelectFinishWork"
              color="error"
              @click="stampTime(2)"
            >
              退勤
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              elevation="2"
              width="160"
              color="primary"
              :disabled="!canSelectBeginBreak"
              @click="stampTime(3)"
            >
              休憩
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              elevation="2"
              width="160"
              :disabled="!canSelectFinishBreak"
              @click="stampTime(4)"
              color="error"
            >
              休憩終了
            </v-btn>
          </v-col>
      </v-row>
    </v-container>
    <v-container class="timestamp" justify="center">
      <v-row no-gutters v-for="(time, index) in attendances" :key="index" justify="center">
        <v-col cols="3" v-if="time.action == '3'"> <v-icon class="mr-2 ml-6"> mdi-coffee</v-icon>休憩開始</v-col>
        <v-col cols="3" v-if="time.action == '4'"> <v-icon class="mr-2 ml-6"> mdi-coffee-outline</v-icon>休憩終了</v-col>
        <v-col cols="3" v-if="time.action == '1'"> <v-icon class="mr-2"> mdi-briefcase</v-icon>出勤</v-col>
        <v-col cols="3" v-if="time.action == '2'"> <v-icon class="mr-2"> mdi-bed</v-icon>退勤</v-col>
        <v-col  cols="3">
          {{ time.created | parseDatetime }}
        </v-col>
        <v-col cols="1" @click="clickEdit(time.id,index)">
          <v-btn icon>
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="1"
          @click="clickDelete(time.id)"
          v-if="index === attendances.length - 1"
        >
          <v-btn icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1" v-else />
      </v-row>
    </v-container>
    <v-container>
      <v-row justify="space-around">
        <v-col cols="3"> 
          <v-card>
            <v-card-title>
              <v-icon class="mr-3">mdi-clock-time-five-outline</v-icon>
              勤務時間
            </v-card-title>
            <v-card-text class="ml-4">
              <v-col class="text-h5">
                {{ nowTodayWorktime }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3"> 
          <v-card>
            <v-card-title>
              <v-icon class="mr-3">mdi-clock-time-three-outline</v-icon>
              休憩時間
            </v-card-title>
            <v-card-text class="ml-4">
              <v-col class="text-h5">
                {{ nowTodayBreaktime }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col cols="3"> 
          <v-card>
            <v-card-title>
              <v-icon class="mr-3">mdi-clock-time-nine-outline</v-icon>
              残業時間 
            </v-card-title>
            <v-card-text class="ml-4">
              <v-col class="text-h5">
                {{ nowTodayBreaktime }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col> -->
      </v-row>
    </v-container>
    <v-dialog v-model="editDialog" width="400">
      <v-card height=550>
        <v-card-title class="headline">編集</v-card-title>
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
              <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
              <v-icon>mdi-clock</v-icon>
          </v-tab>
          <v-tab-item key="calendar" height="800">
            <v-date-picker v-model="selectedDate" full-width></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer" height="800">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="selectedTime"
              full-width
              format="24hr"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
          <v-spacer></v-spacer>
      </v-card>
      <v-card>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="editDialog = false">
            キャンセル
          </v-btn>
          <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="editItem(selectedId)">
          OK
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">削除確認</v-card-title>
        <v-card-text>ID:{{}}を削除してもよろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false"
            >キャンセル</v-btn
          >
          <v-btn color="green darken-1" text @click="deleteItem(selectedId)"
            >削除</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment/moment";
import firebase from "firebase";
import { mapState ,mapGetters } from 'vuex'


export default {
  data() {
    return {
      alignments: ["start"],
      deleteDialog: false,
      editDialog: false,
      selectedId: null,
      selectedIndex: null,
      selectedTime: null,
      selectedDate: null,
      activeTab: 1,
      date: this.defaultDate,
      menu: false,
      nowTime:null,
      baseTime:null
    }
  },
  props:{
    defaultDate:{
      type: String,
      default: moment().format("YYYY/MM/DD")
    }
  },
  filters: {
    parseDatetime(dateTime) {
      return moment(dateTime).format("MM月DD日  HH:mm");
    },
    parseTime (time){
      if(time){
        return time.split('.')[0]
      }
      return time
    }
  },
  computed: {
    ...mapGetters(['attendances']),
    ...mapState({
      currentAction: state => state.currentAction,
      todayWorktime: state => state.worktime,
      todayBreaktime: state => state.breaktime
    }),
    isWorking() {
      return this.currentAction === 1 || this.currentAction == 4
    },
    isBreaking() {
      return this.currentAction === 3
    },
    isAttending() {
      return this.isWorking || this.isBreaking
    },
    isToday () {
    return moment().format("YYYY-MM-DD") === moment(this.date).format("YYYY-MM-DD")
    },
    nowTodayWorktime (){
      if(this.todayWorktime===undefined){
        return 0
      }
      if(!this.isWorking || !this.isToday){
        return this.todayWorktime.split('.')[0]
      }
      const milliseconds = moment.duration(this.todayWorktime)._milliseconds + (this.nowTime - this.baseTime)
      return moment.utc(milliseconds).format('HH:mm:ss')
    },
    nowTodayBreaktime (){
      if(this.todayBreaktime===undefined){
        return 0
      }
      if(!this.isBreaking || !this.isToday ){
        return this.todayBreaktime.split('.')[0]
      }
      const milliseconds = moment.duration(this.todayBreaktime)._milliseconds + (this.nowTime - this.baseTime)
      return moment.utc(milliseconds).format('HH:mm:ss')
    },
    dateSelected () {
      return !this.selectedDate
    },
    canSelectBeginWork() {
      return !(this.isAttending)
    },
    canSelectFinishWork() {
      return this.isWorking
    },
    canSelectBeginBreak() {
      return this.isWorking
    },
    canSelectFinishBreak() {
      return this.isBreaking
    },
  },
  created () {
    this.date = this.defaultDate
    this.updateAttedances()
    this.$store.dispatch("fetchCurrentStatus");
    // this.$store.dispatch("fetchSummaryWork");
    this.updateSummary()
    this.$nextTick(function () {
      window.setInterval(() => {
        this.activeUpdateSummary();
        },1000); // 1秒に一回更新
    })
    this.baseTime = moment.duration(moment().format('HH:mm:ss'))
  },
  methods: {
    activeUpdateSummary(){
      this.nowTime =  moment.duration(moment().format('HH:mm:ss'))
      
    },
    async stampTime(status) {
      let user = await firebase.auth().currentUser;
      await this.$store.dispatch("stampTime", { action: status, user: user.uid });
      await this.$store.dispatch("fetchSummaryWork",{ gt:moment(this.date).format('YYYY-MM-DDT00:00:00'), lt:moment(this.date).format('YYYY-MM-DDT23:59:59') })
      this.baseTime = moment.duration(moment().format('HH:mm:ss'))
    },
    clickEdit(id,index) {
      this.selectedId = id;
      this.selectedIndex = index
      this.selectedDate = moment(this.attendances[index].created).format('YYYY-MM-DD'),
      this.selectedTime = moment(this.attendances[index].created).format('HH:mm')
      this.editDialog = true;
    },
    async editItem (id) {
      await this.$store.dispatch("editStampTime",{id, editedTime: this.selectedDate + 'T' + this.selectedTime,index: this.selectedIndex });
      this.editDialog = false;
      await this.$store.dispatch("fetchSummaryWork",{ gt:moment(this.date).format('YYYY-MM-DDT00:00:00'), lt:moment(this.date).format('YYYY-MM-DDT23:59:59') })
      this.baseTime = moment.duration(moment().format('HH:mm:ss'))

    },
    clickDelete(id) {
      this.selectedId = id;
      this.deleteDialog = true;
    },
    deleteItem(id) {
      this.$store.dispatch("deleteTimestamp",{id});
      this.deleteDialog = false;
      // await sleep(1000)
      this.$store.dispatch("fetchSummaryWork",{ gt:moment(this.date).format('YYYY-MM-DDT00:00:00'), lt:moment(this.date).format('YYYY-MM-DDT23:59:59') })
      this.baseTime = moment.duration(moment().format('HH:mm:ss'))
    },
    updateAttedances (){
      this.$store.dispatch("fetchAttendances",{ createdAfter:moment(this.date).format('YYYY-MM-DDT00:00:00'), createdBefore:moment(this.date).format('YYYY-MM-DDT23:59:59') });
    },
    updateSummary() {
      this.$store.dispatch("fetchSummaryWork",{ gt:moment(this.date).format('YYYY-MM-DDT00:00:00'), lt:moment(this.date).format('YYYY-MM-DDT23:59:59') });
    },
    updateDate (date) {
      this.$refs.menu.save(date)
      this.updateAttedances()
      this.updateSummary()
    },
  }
};
</script>

<style scoped>
.timestamp {
  height: 500px;
  overflow: auto;
}
</style>