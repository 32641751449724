import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// NOTE: temporarily disabled
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

// Vuexのstateで一元管理する対象の状態を定義
const state = {
  account: {
    user: {
      name: null,
    },
  },
  firebaseToken: null,
  attendances: [],
  currentAction: null,
  todayBreaktime: '',
  todayWorktime: '',
  historyList: [],
  isLoading: false,
  fetchSummaryMonthQueryMonth: null,
}

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
  ]
})
if (module.hot) {
  module.hot.accept(['./actions', './getters', './mutations'], () => {
    const newActions = require('./actions').default
    const newGetters = require('./getters').default
    const newMutations = require('./mutations').default
    store.hotUpdate({
      actions: newActions,
      getters: newGetters,
      mutations: newMutations
    })
  })
}

export default store;
