import { ApiClient, Attendance, AttendancesApi, PatchedAttendance } from "@/attendance_api";
import firebase from "firebase/app";
import "firebase/auth";
import moment from "moment/moment";
import * as types from './mutation-types';


function fetchFirebaseUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user) => {
        unsubscribe();
        resolve(user);
      },
      (err) => reject(err)
    );
  });
}

async function fetchFirebaseToken(commit, state) {
  if (state.firebaseToken) {
    const expirationTime = new Date(state.firebaseToken.expirationTime);
    if (new Date() < expirationTime) {
      return state.firebaseToken.token;
    }
  }
  let user = firebase.auth().currentUser;
  if (!user) {
    user = await fetchFirebaseUser();
  }
  if (!user) {
    throw new Error("not logged in");
  }
  const forceRefresh = false;
  const { token, expirationTime } = await user.getIdTokenResult(forceRefresh);
  const safeMarginMsec = 60 * 1000 // 1 minute
  commit(types.AUTH_SET_TOKEN, {
    token,
    expirationTime: Number(new Date(expirationTime)) - safeMarginMsec
  })
  return token;
}

export default {
  stampTime: async ({ commit, state }, { action, user }) => {
    let Bearer = ApiClient.instance.authentications["FirebaseAuth"];
    Bearer.accessToken = await fetchFirebaseToken(commit, state);
    let apiInstance = new AttendancesApi();
    apiInstance.apiClient.basePath = "https://attendance-q4he5pdu3a-uc.a.run.app";
    let attendance = new Attendance();
    attendance.user = user;
    attendance.action = action;
    try {
      let data = await apiInstance.attendancesCreate(attendance);
      commit(types.ADD_ATTENDANCES, data)
    }
    catch (error) {
      console.log(error)
    }
  },
  deleteTimestamp: async ({ commit, state }, { id }) => {
    let Bearer = ApiClient.instance.authentications["FirebaseAuth"];
    Bearer.accessToken = await fetchFirebaseToken(commit, state);
    let apiInstance = new AttendancesApi();
    apiInstance.apiClient.basePath = "https://attendance-q4he5pdu3a-uc.a.run.app";
    try{
      await apiInstance.attendancesDestroy(id)
      commit(types.DELETE_TIMESTAMP)
    }
    catch(error){
      console.log(error)
    }
  } ,
  fetchAttendances: async ({ commit, state },{ createdAfter, createdBefore }) => {
    let Bearer = ApiClient.instance.authentications["FirebaseAuth"];
    Bearer.accessToken = await fetchFirebaseToken(commit, state);
    let user = await fetchFirebaseUser();
    let apiInstance = new AttendancesApi();
    apiInstance.apiClient.basePath = "https://attendance-q4he5pdu3a-uc.a.run.app";
    let opts = {
      'createdAfter': new Date(createdAfter), // Date | 
      'createdBefore': new Date(createdBefore), // Date | 
      'page': 1, // Number | A page number within the paginated result set.
      'user': user.uid,
    }
    try {
    const data = await apiInstance.attendancesList(opts)
      commit(types.GET_ATTENDANCES, data)
    }
    catch (error) {
      console.error(error);
    }
  },

  fetchCurrentStatus : async ({ commit, state }) => {
    let Bearer = ApiClient.instance.authentications["FirebaseAuth"];
    Bearer.accessToken = await fetchFirebaseToken(commit, state);
    let apiInstance = new AttendancesApi();
    apiInstance.apiClient.basePath = "https://attendance-q4he5pdu3a-uc.a.run.app";
    try {
      const data = await apiInstance.attendancesCurrentRetrieve()
      commit(types.GET_CURRENT_ACTION, data)
    }
    catch (error){
      console.error(error);
    }
  },
  fetchSummaryWork : async ({ commit, state }, {lt, gt} ) => {
    let Bearer = ApiClient.instance.authentications["FirebaseAuth"];
    Bearer.accessToken = await fetchFirebaseToken(commit, state);
    let apiInstance = new AttendancesApi();
    let opt = {
      lt : lt,
      gt : gt
    }
    apiInstance.apiClient.basePath = "https://attendance-q4he5pdu3a-uc.a.run.app";
    try{
      const data = await apiInstance.attendancesRemainWorktimeRetrieve(opt)
      commit(types.GET_TODAY_SUMMARY,data)
    } 
    catch (error){
      console.error(error);
    }
  },
  editStampTime : async ({ commit, state }, { id, editedTime, index}) => {
    let Bearer = ApiClient.instance.authentications["FirebaseAuth"];
    Bearer.accessToken = await fetchFirebaseToken(commit, state);
    let apiInstance = new AttendancesApi();
    apiInstance.apiClient.basePath = "https://attendance-q4he5pdu3a-uc.a.run.app";
    let patchedAttendance = new PatchedAttendance();
    patchedAttendance.created =  new Date(editedTime) 
    patchedAttendance.id = id
    let opts = {
      patchedAttendance
    };
    try {
      const data = await apiInstance.attendancesPartialUpdate(id, opts)        
      commit(types.EDIT_ATTENDANCE ,{data,index})
    }
    catch(error)  {
      console.error(error);
    }
  },
  fetchSummaryMonth : async ({ commit, state },month) => {
    if(state.fetchSummaryMonthQueryMonth === month){
      return
    }
    commit(types.TOGGLE_LOADING)
    let startDate =  moment(month+'-01')
    let endDate =  moment(month+'-01').add(1, 'months')

    let dateList = new Array()
    let Bearer = ApiClient.instance.authentications["FirebaseAuth"];
    Bearer.accessToken = await fetchFirebaseToken(commit, state);
    let apiInstance = new AttendancesApi();

    apiInstance.apiClient.basePath = "https://attendance-q4he5pdu3a-uc.a.run.app";
    var promises = [];
    for(let date =startDate; date < endDate;){
        let opt = {
          lt : date.format('YYYY-MM-DDT23:59:59'),
          gt : date.format('YYYY-MM-DDT00:00:00')
        }
        dateList.push(date.format('YYYY-MM-DD'))
        promises.push(apiInstance.attendancesRemainWorktimeRetrieve(opt))
        date=date.add(1,'days')
    }
    Promise.all(promises).then( async (historyList) => {
      for( let i in dateList ){
        historyList[i].date = dateList[i]
      }
      await commit(types.GET_MONTHLY_SUMMARY , { data: historyList, month: month} )
    })
    .finally(() => {
      commit(types.TOGGLE_LOADING)
    });
  }
};
