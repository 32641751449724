<template>
  <div>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pl-12"
    >
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="month"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="month"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="month"
          no-title
          type="month"
          locale="ja"
          :show-current="false"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="updateMonth(month)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <!-- <v-container class="mt-5">
      <v-row justify="space-around">
        <v-col cols="3"> 
          <v-card>
            <v-card-title>
              <v-icon class="mr-3">mdi-clock-time-five-outline</v-icon>
              勤務時間
            </v-card-title>
            <v-card-text class="ml-4">
              <v-col class="text-h5">
                {{ todayWorktime }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3"> 
          <v-card>
            <v-card-title>
              <v-icon class="mr-3">mdi-clock-time-three-outline</v-icon>
              休憩時間
            </v-card-title>
            <v-card-text class="ml-4">
              <v-col class="text-h5">
                {{ todayBreaktime }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3"> 
          <v-card>
            <v-card-title>
              <v-icon class="mr-3">mdi-clock-time-nine-outline</v-icon>
              残業時間 
            </v-card-title>
            <v-card-text class="ml-4">
              <v-col class="text-h5">
                {{ todayBreaktime }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container> -->

        <v-container>
      <v-row justify="space-around">
        <v-col cols="3"> 
          <v-card>
            <v-card-title>
              <v-icon class="mr-3">mdi-clock-time-three-outline</v-icon>
              勤務日数
            </v-card-title>
            <v-card-text class="ml-4">
              <v-col class="text-h5">
                {{ countAttendance }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3"> 
          <v-card>
            <v-card-title>
              <v-icon class="mr-3">mdi-clock-time-five-outline</v-icon>
              勤務時間合計
            </v-card-title>
            <v-card-text class="ml-4">
              <v-col class="text-h5">
                {{ summaryWorkTime }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3"> 
          <v-card>
            <v-card-title>
              <v-icon class="mr-3">mdi-clock-time-three-outline</v-icon>
              休憩時間合計
            </v-card-title>
            <v-card-text class="ml-4">
              <v-col class="text-h5">
                {{ summarybreakTime }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
    :headers="headers"
    :items="historyList"
    :items-per-page="31"
    class="elevation-1 mt-10 px-16"
    height="600"
    :loading="isLoading"
    @click:row="clickRow"
    hide-default-footer>
    <template v-slot:item.date="{ item }">
      <span
      :class="classes(item.date)">
        {{ item.date }}
      </span>
    </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from "moment/moment";
import * as holiday_jp from '@holiday-jp/holiday_jp'

export default {
  data(){
    return {
      month: new Date().toISOString().substr(0, 7),
      menu: false,
      headers: [
        {
          text: '日付',
          align: 'start',
          value: 'date',
          sortable: false,
        },
        {
          text: '出勤時間',
          align: 'start',
          value: 'worktime',
          sortable: false,
        },
        {
          text: '休憩時間',
          align: 'start',
          value: 'breaktime',
          sortable: false,
        },
        {
          text: '残業時間',
          align: 'start',
          value: '',
          sortable: false,
        },
      ],
    }
  },
  computed:{
    ...mapState({
      historyList: state => state.historyList,
      isLoading: state => state.isLoading
    }),
    classes(){
      return function(value) {
        return this.getColor(value)
      }
    },
    summaryWorkTime(){
      const sumWorkTimeMS = this.historyList.reduce((prev,history)=> prev + moment.duration(history.worktime),0)
      const hour = parseInt(sumWorkTimeMS / 3600000)
      const minute = parseInt((sumWorkTimeMS % 3600000) / 60000)
      const second = parseInt(((sumWorkTimeMS % 3600000) % 60000) / 1000)
      return hour.toString(10) + '時間' + minute.toString(10) + '分' + second.toString(10) + '秒'
    },
    summarybreakTime(){
      const sumWorkTimeMS = this.historyList.reduce((prev,history)=> prev + moment.duration(history.breaktime),0)
      const hour = parseInt(sumWorkTimeMS / 3600000)
      const minute = parseInt((sumWorkTimeMS % 3600000) / 60000)
      const second = parseInt(((sumWorkTimeMS % 3600000) % 60000) / 1000)
      return hour.toString(10) + '時間' + minute.toString(10) + '分' + second.toString(10) + '秒'
    },
    countAttendance () {
      return this.historyList.filter((history) => moment.duration(history.worktime) > 0 ).length.toString(10) + '日'
    }
  },
  methods:{
    getColor (date) {
      if (moment(date).day() === 0 || holiday_jp.isHoliday(date)){
        return 'red--text'
      }
      else if (moment(date).day()===6) {
        return 'blue--text'
      }
      return ''
    },
    async updateMonth(month){
      this.$refs.menu.save(month)
      await this.updateHistory()
    },
    async updateHistory() {
      await this.$store.dispatch("fetchSummaryMonth",this.month)
    },
    clickRow(data){
      this.$router.push({ name: "attendance" , params: {defaultDate : data.date}})
    }
  },
  async created () {
    await this.updateHistory()
  },
}
</script>