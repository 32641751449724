/**
 * Attendance API
 * 勤怠管理API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TimeSummary model module.
 * @module model/TimeSummary
 * @version 1.0.0
 */
class TimeSummary {
    /**
     * Constructs a new <code>TimeSummary</code>.
     * @alias module:model/TimeSummary
     * @param worktime {String} 
     * @param remainWorktime {String} 
     * @param breaktime {String} 
     * @param remainBreaktime {String} 
     */
    constructor(worktime, remainWorktime, breaktime, remainBreaktime) { 
        
        TimeSummary.initialize(this, worktime, remainWorktime, breaktime, remainBreaktime);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, worktime, remainWorktime, breaktime, remainBreaktime) { 
        obj['worktime'] = worktime;
        obj['remain_worktime'] = remainWorktime;
        obj['breaktime'] = breaktime;
        obj['remain_breaktime'] = remainBreaktime;
    }

    /**
     * Constructs a <code>TimeSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TimeSummary} obj Optional instance to populate.
     * @return {module:model/TimeSummary} The populated <code>TimeSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TimeSummary();

            if (data.hasOwnProperty('worktime')) {
                obj['worktime'] = ApiClient.convertToType(data['worktime'], 'String');
            }
            if (data.hasOwnProperty('remain_worktime')) {
                obj['remain_worktime'] = ApiClient.convertToType(data['remain_worktime'], 'String');
            }
            if (data.hasOwnProperty('breaktime')) {
                obj['breaktime'] = ApiClient.convertToType(data['breaktime'], 'String');
            }
            if (data.hasOwnProperty('remain_breaktime')) {
                obj['remain_breaktime'] = ApiClient.convertToType(data['remain_breaktime'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} worktime
 */
TimeSummary.prototype['worktime'] = undefined;

/**
 * @member {String} remain_worktime
 */
TimeSummary.prototype['remain_worktime'] = undefined;

/**
 * @member {String} breaktime
 */
TimeSummary.prototype['breaktime'] = undefined;

/**
 * @member {String} remain_breaktime
 */
TimeSummary.prototype['remain_breaktime'] = undefined;






export default TimeSummary;

