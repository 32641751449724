<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import 'ress'

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: noto-sans-cjk-jp, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  height: 100%;
  font-size: 1.6rem;
}
</style>