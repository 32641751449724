import Vue from 'vue'
import * as types from './mutation-types'
import moment from "moment/moment";

export default {
  [types.AUTH_SIGNIN] (state, payload) {
    state.account = payload.account
  },
  [types.AUTH_SET_TOKEN] (state, { token, expirationTime }) {
    state.firebaseToken = {
      token,
      expirationTime
    }
  },
  [types.GET_ATTENDANCES](state,payload){
    Vue.set(state, 'attendances', payload.results)
  },
  [types.GET_CURRENT_ACTION](state,payload){
    Vue.set(state, 'currentAction', payload.action)
  },
  [types.ADD_ATTENDANCES](state,payload){
    let attendances =  state.attendances.slice()
    attendances.unshift(payload)
    Vue.set(state, 'attendances', attendances)
    Vue.set(state, 'currentAction', payload.action)
  },

  [types.GET_TODAY_SUMMARY](state,payload){

    Vue.set(state, 'worktime', payload.worktime)
    Vue.set(state, 'remain_worktime', payload.remain_worktime)
    Vue.set(state, 'breaktime', payload.breaktime)
    Vue.set(state, 'remain_breaktime', payload.remain_breaktime)
  },
  [types.DELETE_TIMESTAMP] (state) {
    let attendances = state.attendances.slice()
    attendances.shift()
    Vue.set(state, 'attendances', attendances)
    Vue.set(state, 'currentAction', attendances[0].action)
  },
  [types.EDIT_ATTENDANCE] (state,{data,index}) {
    let attendances = state.attendances.slice().reverse()
    attendances[index] = data
    attendances = attendances.reverse()
    Vue.set(state, 'attendances', attendances)
  },
  [types.GET_MONTHLY_SUMMARY] (state,{ data, month} ){
    Vue.set(state, 'fetchSummaryMonthQueryMonth', month)
    data = data.map(item => {
      item.worktime = moment.utc( moment.duration(item.worktime)._milliseconds ).format('HH:mm:ss')
      item.breaktime = moment.utc( moment.duration(item.breaktime)._milliseconds ).format('HH:mm:ss')
      return item
    })
    Vue.set(state, 'historyList', data)
  },
  [types.TOGGLE_LOADING] (state){
    state.isLoading = !state.isLoading
  }

}