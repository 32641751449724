import Attendance from "@/components/Attendance.vue";
import History from "@/components/History.vue";
import DatetimePicker from 'vuetify-datetime-picker'
import Login from "@/components/Login.vue";
import firebase from "firebase/app";
import Vue from "vue";
import Router from "vue-router";
import App from "./App.vue";
import Base from "./Base.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";

Vue.use(Router);
Vue.use(DatetimePicker)

Vue.config.productionTip = false;

const firebaseConfig = require("./config/firebase.js");
firebase.initializeApp(firebaseConfig);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: () => {
        return "/attendance";
      },
      meta: { requiresAuth: true },
    },
    {
      name: "Dashboard",
      path: "/",
      component: Base,
      props: (route) => ({
        defaultDate: route.params.defaultDate
      }),
      meta: { requiresAuth: true },
      children: [
        {
          name: "attendance",
          path: "attendance",
          components: {
            default: Attendance,
          },
          meta: { requiresAuth: true },
        },
        {
          name: "history",
          path: "history",
          components: {
            default: History,
          },
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      name: "Login",
      path: "/login",
      components: {
        default: Login,
      },
    },
    {
      path: '*',
      redirect: () => {
        return "/";
      }
    }
  ],
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth) {
    // 認証状態を取得
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        next();
      } else {
        // 認証されていない場合、認証画面へ
        next({ name: "Login" });
      }
    });
  } else {
    next();
  }
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
